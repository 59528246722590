import React, { useState, useEffect } from "react";
import "./Landing.css";
import about from "../Assets/About.png";
import cross from "../Assets/cross.svg";
import logo from "../Assets/RS_Logo.svg";
import emp1 from "../Assets/ashish.jpeg";
import emp2 from "../Assets/mahendar (1).jpeg";
import emp3 from "../Assets/vinod.jpg";
import emp4 from "../Assets/allunur khan.jpg";
import emp5 from "../Assets/Gourav Mehto.jpeg";
import Primelocation from "../Assets/choose us/location.png";
import Timely from "../Assets/choose us/key.png";
import quality from "../Assets/choose us/guarantee.png";
import morden from "../Assets/choose us/amenities (1).png";
import name from "../Assets/name.svg";
import location from "../Assets/location.svg";
import requiredment from "../Assets/Requriment.svg";
import phone from "../Assets/phone.svg";
import comercial from "../Assets/Comercial/WhatsApp Image 2024-07-03 at 2.38.21 PM.jpeg";
import comercial2 from "../Assets/Comercial/WhatsApp Image 2024-07-03 at 2.38.22 PM.jpeg";
import comercial3 from "../Assets/Comercial/WhatsApp Image 2024-07-03 at 2.39.55 PM.jpeg";
import comercial4 from "../Assets/Comercial/WhatsApp Image 2024-07-03 at 2.39.56 PM (2).jpeg";
import comercial5 from "../Assets/Comercial/WhatsApp Image 2024-07-03 at 2.39.56 PM.jpeg";
import residental from "../Assets/Resedential/WhatsApp Image 2024-07-03 at 2.38.24 PM.jpeg";
import residental2 from "../Assets/Resedential/WhatsApp Image 2024-07-03 at 2.39.50 PM.jpeg";
import residental3 from "../Assets/Resedential/WhatsApp Image 2024-07-03 at 2.39.55 PM (1).jpeg";
import residental4 from "../Assets/Resedential/WhatsApp Image 2024-07-03 at 2.39.56 PM (1).jpeg";
import residental5 from "../Assets/Resedential/WhatsApp Image 2024-07-03 at 2.39.56 PM.jpeg";
import Coveragebuy from "../Assets/Buying Property Made Easy/Coverage.svg";
import Geniinebuy from "../Assets/Buying Property Made Easy/Genuine.svg";
import hasslebuy from "../Assets/Buying Property Made Easy/Hassle.svg";
import Consultant from "../Assets/Our Services/Consultant.svg";
import contract from "../Assets/Our Services/Contract-01-01.svg";
import vastu from "../Assets/Our Services/Vastu_shastra-01-01.svg";
import intertior from "../Assets/Our Services/interior-01.svg";
import investment from "../Assets/Our Services/investment-01.svg";
import planning from "../Assets/Our Services/planning-01.svg";
import porperty from "../Assets/Our Services/property-01.svg";
import realstates from "../Assets/Our Services/REAL_ESTATE-01-01.svg";
import renovationservice from "../Assets/Our Services/Renovation-01.svg";
import menu from "../Assets/menu.svg";
import clock from "../Assets/clock.svg";
import email from "../Assets/sms.svg";
import sonu from "../Assets/sonu.jpg";
import watapp from "../Assets/Whatsapp.svg";
import facebook from "../Assets/Facebook.svg";
import nagarnigam from "../Assets/Gov Project/nagar nigam/WhatsApp Image 2024-07-03 at 5.55.21 PM (1).jpeg";
import nagarnigam2 from "../Assets/Gov Project/nagar nigam/WhatsApp Image 2024-07-03 at 5.55.21 PM.jpeg";
import nagarnigam3 from "../Assets/Gov Project/nagar nigam/WhatsApp Image 2024-07-03 at 5.55.22 PM (1).jpeg";
import nagarnigam4 from "../Assets/Gov Project/nagar nigam/WhatsApp Image 2024-07-03 at 5.55.22 PM (2).jpeg";
import nagarnigam5 from "../Assets/Gov Project/nagar nigam/WhatsApp Image 2024-07-03 at 5.55.22 PM.jpeg";
import kalanipura from "../Assets/Gov Project/Kalyanipura/WhatsApp Image 2024-07-03 at 6.09.11 PM (1).jpeg";
import kalanipura2 from "../Assets/Gov Project/Kalyanipura/WhatsApp Image 2024-07-03 at 6.09.11 PM.jpeg";
import kalanipura3 from "../Assets/Gov Project/Kalyanipura/WhatsApp Image 2024-07-03 at 6.09.12 PM (1).jpeg";
import kalanipura4 from "../Assets/Gov Project/Kalyanipura/WhatsApp Image 2024-07-03 at 6.09.12 PM.jpeg";
import kalanipura5 from "../Assets/Gov Project/Kalyanipura/WhatsApp Image 2024-07-03 at 6.09.13 PM.jpeg";
import road from "../Assets/Gov Project/Cement Road/WhatsApp Image 2024-07-03 at 5.59.44 PM.jpeg";
import road2 from "../Assets/Gov Project/Cement Road/WhatsApp Image 2024-07-03 at 5.59.45 PM (1).jpeg";
import road3 from "../Assets/Gov Project/Cement Road/WhatsApp Image 2024-07-03 at 5.59.45 PM (2).jpeg";
import road4 from "../Assets/Gov Project/Cement Road/WhatsApp Image 2024-07-03 at 5.59.45 PM.jpeg";
import road5 from "../Assets/Gov Project/Cement Road/WhatsApp Image 2024-07-03 at 5.59.46 PM (1).jpeg";
import road6 from "../Assets/Gov Project/Cement Road/WhatsApp Image 2024-07-03 at 5.59.46 PM.jpeg";
import divider from "../Assets/Gov Project/Mittal Divider/WhatsApp Image 2024-07-03 at 5.51.51 PM (1).jpeg";
import divider2 from "../Assets/Gov Project/Mittal Divider/WhatsApp Image 2024-07-03 at 5.51.51 PM (2).jpeg";
import divider3 from "../Assets/Gov Project/Mittal Divider/WhatsApp Image 2024-07-03 at 5.51.52 PM (1).jpeg";
import divider4 from "../Assets/Gov Project/Mittal Divider/WhatsApp Image 2024-07-03 at 5.51.52 PM.jpeg";
import emp7 from '../Assets/Amir khan.jpeg'

const teamMembers = [
  { id: 1, name: "Ashish jangid sir", role: " senior architect", image: emp1 },
  { id: 2, name: "Mahendra Kumar nangliya", role: "Role 2", image: emp2 },
  { id: 3, name: "Vinod kumar", role: "Flooring work", image: emp3 },
  {
    id: 4,
    name: " Allanur Khan",
    role: "Work  shuttering and centering eng.",
    image: emp4,
  },
  { id: 5, name: "Sonu Agarwal", role: "Manager", image: sonu },
  { id: 6, name: "Gourav Mehto", role: "Accountant", image: emp5 },
  { id: 7, name: 'Amir Khan', role: 'Role 7', image: emp7 },
  // { id: 8, name: 'Member 8', role: 'Role 8', image: 'path/to/image8.jpg' },
];
const projects = [
  {
    name: "Nagar Nigam Project",
    // location: "Location 1",
    // description: "Some Description for Project 1",
    image: nagarnigam,
    thumbnails: [nagarnigam2, nagarnigam3, nagarnigam4, nagarnigam5],
  },
  {
    name: "Mittal Divider Project",
    // location: "Location 2",
    // description: "Some Description for Project 2",
    image: divider,
    thumbnails: [divider2, divider3, divider4],
  },
  {
    name: "Cemmnet Road Project",
    // location: "Location 2",
    // description: "Some Description for Project 2",
    image: road,
    thumbnails: [road2, road3, road4, road5, road6],
  },
  {
    name: "Kalyanipura Project",
    // location: "Location 2",
    // description: "Some Description for Project 2",
    image: kalanipura,
    thumbnails: [kalanipura2, kalanipura3, kalanipura4, kalanipura5, ,],
  },
  // Add other projects here
];

function Landing() {
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
  const [displayedImage, setDisplayedImage] = useState(projects[0].image);

  useEffect(() => {
    setDisplayedImage(projects[currentProjectIndex].image);
  }, [currentProjectIndex, projects]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentProjectIndex((prevIndex) =>
        prevIndex === projects.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); // Change the project every 3 seconds

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [projects.length]);

  const prevProject = () => {
    setCurrentProjectIndex((prevIndex) =>
      prevIndex === 0 ? projects.length - 1 : prevIndex - 1
    );
  };

  const nextProject = () => {
    setCurrentProjectIndex((prevIndex) =>
      prevIndex === projects.length - 1 ? 0 : prevIndex + 1
    );
  };
  const [toogle, settoggle] = useState(false);
  

  const [showMore, setShowMore] = useState(false);


  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
    console.log("is not")
  };
  return (
    <div>
      {toogle === false ? (
        <>
          <div className="container-fluid backcolor">
            <div className="container ">
              <div className="row ">
                <div
                  className="col-lg-5  mt-4 mb-4 col-9 ps-5 justify-content-center"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={logo}
                    alt=""
                    className="w-25 "
                    // onClick={() => {
                    //   navigate("/");
                    // }}
                  />
                </div>

                <div className="col-lg-7   col-2 pe-5   d-flex justify-content-end ">
                  <div className="row mt-3 ">
                    <div className="col-12 mt-4 d-none d-lg-block">
                      <ul className="list-inline">
                        <li
                          className="list-inline-item p-2 pe-5   fs-5"
                          style={{ cursor: "pointer", color: "white" }}
                          onClick={() => scrollToSection("About")}
                        >
                          About Us
                        </li>
                        <li
                          className="list-inline-item p-2 fs-5 pe-5"
                          style={{ cursor: "pointer", color: "white" }}
                          onClick={() => scrollToSection("service")}
                        >
                          Service
                        </li>
                        <li
                          className="list-inline-item p-2 fs-5 pe-5"
                          style={{ cursor: "pointer", color: "white" }}
                          onClick={() => scrollToSection("ourWork")}
                        >
                          Our Work
                        </li>
                        <li
                          className="list-inline-item p-2 fs-5 pe-5"
                          style={{ cursor: "pointer", color: "white" }}
                          onClick={() => scrollToSection("contact") }
                          
                        >
                          Contact Us
                        </li>
                      </ul>
                    </div>
                    <div className="col d-lg-none">
                      <img
                        src={menu}
                        alt=""
                        onClick={(e) => settoggle(!toogle)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {toogle === true ? (
        <>
          <div className="container-fluid mobile menu">
            <div className="row">
              <div className="col-lg-12 mt-5">
                <div className="row">
                  <div className="col-8 text-start ps-5"></div>
                  <div className="col-4">
                    <img
                      src={cross}
                      alt="Close"
                      onClick={() => settoggle(!toogle)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="col-11">
                <ul
                  className="fw-bold mt-5"
                  style={{
                    listStyle: "none",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  <li className="p-3 fs-5" style={{ cursor: "pointer" }} 
                  onClick={()=>{settoggle(!toogle)}}>
                    About Us
                  </li>
                  <li className="p-3 fs-5" style={{ cursor: "pointer" }} onClick={() => scrollToSection("service")}>
                    Services
                  </li>
                  <li className="p-3 fs-5" style={{ cursor: "pointer" }} onClick={() => scrollToSection("ourWork")}>
                    Our Work
                  </li>
                  <li className="p-3 fs-5" style={{ cursor: "pointer" }} onClick={() => scrollToSection("contact")}>
                    Contact Us
                  </li>
                </ul>
              </div>
            </div>

            <div className="row mt-4 text-center">
              <div className="col-12">
                <img src={logo} className="w-50" alt="Logo" />
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {toogle === false ? (
        <>
          {/* Hero Section */}
          <div className="container-fluid hero">
            <div className="container">
              <div className="row">
                <div className="col-12   text-center">
                  <h1
                    style={{
                      fontSize: "75px",
                      fontWeight: "700,",
                      color: "white",
                    }}
                    className="mt-3"
                  >
                    RS Properties & Builders
                  </h1>
                </div>
                <div className="text-center fs-5 mb-3 mt-3 ">
                  <p style={{ color: "white" }}>
                    From luxurious homes and serene farmhouses to prime
                    commercial spaces, explore our extensive listings tailored
                    to meet your unique lifestyle and business needs. Find the
                    perfect place to live or work with RS Properties..
                  </p>
                </div>
              </div>

              <div className="row hero2 p-3">
                <div className="col-md-3 ">
                  <div className="row">
                    <div className="col-1 p-2">
                      <img src={name} alt="" />
                    </div>
                    <div className="col-9">
                      <div className="p-2">
                        <input
                          type="text"
                          className="form-control"
                          name=""
                          id=""
                          placeholder="Name"
                          style={{ border: "none" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-3">
                  <div className="row">
                    <div className="col-1 p-2">
                      <img src={phone} alt="" />
                    </div>
                    <div className="col-9">
                      <div className="p-2">
                        <input
                          type="text"
                          className="form-control"
                          name=""
                          id=""
                          placeholder="Phone"
                          style={{ border: "none" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-3 ">
                  <div className="row">
                    <div className="col-1 p-2">
                      <img src={location} className="  " alt="" />
                    </div>
                    <div className="col-9 ">
                      <div className="p-2">
                        <input
                          type="text"
                          className="form-control "
                          name=""
                          id=""
                          placeholder="Location"
                          style={{ border: "none" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="row">
                    <div className="col-8 p-3">
                      <select className="buton p-1 ">
                        {/* <img src={requiredment} alt="" /> */}
                        <option value="">Requirement</option>
                        <option value="RentProperties">Rent Properties</option>
                        <option value="BuyProperties">Buy Properties</option>
                        <option value="pre_ leaseproperties">
                          Pre_ lease Properties
                        </option>
                      </select>
                    </div>
                    <div className="col-4 p-3">
                      <div className="">
                        <button className="buton2 p-1">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" row hero2 mt-5 w-75 text-center p-3 mx-auto ">
                <div className="col-lg-4 .d-sm-none .d-md-block">
                  <div className="row">
                    <div className="col-1">
                      <img src={phone} alt="" />
                    </div>
                    <div className="col-9">
                      <div>
                        <span>Phone</span>
                      </div>
                      <div>
                        <span>+91 9829820009</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="row">
                    <div className="col-1">
                      <div>
                        <img src={clock} alt="" />
                      </div>
                    </div>
                    <div className="col-9">
                      <div className="">
                        <span>Opening Time</span>
                      </div>
                      <div>
                        <span>mon - sat : 10AM - 6Pm</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="row">
                    <div className="col-1">
                      <img src={email} alt="" />
                    </div>

                    <div className="col-9">
                      <div>
                        <span>E-mail address</span>
                      </div>
                      <div>
                        <span>r.sprosaini09@gmail.com</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* about us  */}

          <div className="container-fluid aboutrs" id="About">
            <div className="container p-4  py-5 ">
              <div className="row">
                <div className="col-12">
                  <div className="text-center">
                    <h1 style={{ color: "white" }}>About Rs Properties</h1>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-lg-7">
                  <div className="">
                    <p
                      className="fs-4"
                      style={{ textAlign: "justify", color: "white" }}
                    >
                      <span className="fs-1 ">Welcome</span> to RS Properties,
                      where we redefine the real estate experience. Beyond
                      buying and selling, we craft lifestyles, fulfill dreams,
                      and build futures. With a blend of visionary thinking and
                      meticulous execution, we offer bespoke solutions tailored
                      to your unique aspirations. Whether securing your dream
                      home, expanding your business footprint, or optimizing
                      investments, RS Properties stands ready with expertise and
                      passion to elevate every aspect of your real estate
                      journey. Join us and discover a partnership that
                      transcends the ordinary, forging pathways to extraordinary
                      living and investment opportunities.
                    </p>
                  </div>
                </div>

                <div className="col-lg-5 ">
                  <div className="text-center">
                    <img src={about} className="w-75" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Our Services */}

          <div className="container py-5 p-4" id="service">
            <div className="row">
              <div className="col-12">
                <div className="text-center">
                  <h1>Our Services</h1>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-lg-3 col-6">
                <div className=" text-center">
                  <img src={intertior} className="w-50" alt="" />
                </div>
                <div className="text-center mb-4">
                  <span className="fs-3">Interior & Exterior Designing</span>
                </div>

                <div className="" style={{ textAlign: "justify" }}>
                  We specialize in creating aesthetically pleasing and
                  functional interior and exterior spaces. Our designers blend
                  creativity with practicality to transform your living or
                  working environment, ensuring it reflects your style and meets
                  your needs. From concept to completion, we deliver bespoke
                  designs tailored to your preferences.
                </div>
              </div>

              <div className="col-lg-3 col-6">
                <div className=" text-center">
                  <img src={realstates} className="w-50" alt="" />
                </div>

                <div className="text-center mb-4">
                  <span className="fs-3">Real Estate Development </span>
                </div>
                <div className="" style={{ textAlign: "justify" }}>
                  Our team provides expert real estate development, management,
                  and consulting services, guiding clients through the
                  complexities of property investment and development. We offer
                  strategic advice, market analysis, and project management to
                  maximize property value and ensure successful outcomes.
                </div>
              </div>

              <div className="col-lg-3 col-6">
                <div className=" text-center">
                  <img src={renovationservice} className="w-50" alt="" />
                </div>
                <div className="mb-4 text-center">
                  <span className="fs-3">Renovation and Remodeling</span>
                </div>

                <div className="" style={{ textAlign: "justify" }}>
                  Our renovation and remodeling services breathe new life into
                  your existing spaces. Whether updating a single room or
                  undertaking a complete overhaul, we combine innovative design
                  solutions with skilled craftsmanship. We ensure your project
                  enhances both the functionality and beauty of your property.
                </div>
              </div>

              <div className="col-lg-3 col-6">
                <div className=" text-center">
                  <img src={porperty} className="w-50" alt="" />
                </div>
                <div className="text-center mb-5">
                  <span className="fs-3">Property Valuation</span>
                </div>

                <div className="" style={{ textAlign: "justify" }}>
                  Our property valuation services deliver accurate and
                  comprehensive assessments of real estate assets. Using
                  industry-standard methodologies and market insights, we
                  provide valuations for various purposes, including sales,
                  purchases, and financing, ensuring you make informed
                  decisions.
                </div>
              </div>
            </div>
            {showMore && (
              <div className="row">
                <div className="col-lg-3 col-6">
                  <div className=" text-center">
                    <img src={Consultant} className="w-50" alt="" />
                  </div>

                  <div className="text-center mb-4">
                    <span className="fs-3">
                      Financial Advisory & Investment
                    </span>
                  </div>

                  <div className="" style={{ textAlign: "justify" }}>
                    We provide personalized financial advisory and investment
                    services to help you achieve your financial goals. Our
                    experts offer tailored strategies for wealth management,
                    investment planning, and risk assessment, ensuring your
                    financial future is secure and prosperous.
                  </div>
                </div>

                <div className="col-lg-3 col-6">
                  <div className=" text-center">
                    <img src={planning} className="w-50" alt="" />
                  </div>

                  <div className="text-center mb-4">
                    <span className="fs-3">Urban Planning and Design</span>
                  </div>

                  <div className="" style={{ textAlign: "justify" }}>
                    Our urban planning and design services focus on creating
                    sustainable, livable, and aesthetically pleasing urban
                    environments. We work with stakeholders to develop strategic
                    plans, zoning regulations, and community designs that
                    enhance the quality of life and promote economic growth.
                  </div>
                </div>

                <div className="col-lg-3 col-6">
                  <div className=" text-center">
                    <img src={vastu} className="w-50" alt="" />
                  </div>

                  <div className=" text-center mb-4">
                    <span className="fs-3">Vastu</span>
                  </div>

                  <div className="mt-5" style={{ textAlign: "justify" }}>
                    We integrate the principles of Vastu Shastra into our design
                    and construction projects to promote harmony and positive
                    energy. Our Vastu services include site evaluation, space
                    planning, and architectural adjustments, ensuring that your
                    living or working environment is in balance with natural
                    forces.
                  </div>
                </div>

                <div className="col-lg-3 col-6">
                  <div className=" text-center">
                    <img src={contract} className="w-50" alt="" />
                  </div>
                  <div className="text-center mb-4">
                    <span className="fs-3">General Contracting</span>
                  </div>

                  <div className="mt-5" style={{ textAlign: "justify" }}>
                    We offer comprehensive general contracting services,
                    overseeing all aspects of construction projects from start
                    to finish. Our expertise ensures timely completion, quality
                    craftsmanship, and adherence to budgetary constraints. With
                    a client-focused approach, we manage every detail to bring
                    your vision to life.
                  </div>
                </div>
              </div>
            )}
            <div className="text-center mt-4">
              <button className="show p-3 fs-5 fw-bold" onClick={toggleShowMore}>
                {showMore ? "Show Less" : "Show More"}
              </button>
            </div>
          </div>

          {/* Goverment Projects */}

          <div className="container-fluid bg-dark text-white d-flex flex-column align-items-center">
            <h1 className="mt-5">Government Projects</h1>
            <div className="container mt-4">
              <div className="row justify-content-center">
                <div className="col-1 d-flex align-items-center justify-content-center position-relative d-block">
                  <button
                    className="btn btn-outline-light position-absolute top-50 start-50 translate-middle d-none d-sm-block"
                    onClick={prevProject}
                  >
                    <i className="bi bi-chevron-left d-sm-none "></i>
                  </button>
                </div>
                <div className="col-lg-6 col-md-8 col-sm-10 d-flex flex-column align-items-center">
                  <h2 className="project-name mb-4">
                    {projects[currentProjectIndex].name}
                  </h2>
                  <div className="project-image mb-3 w-50 mx-auto">
                    <img
                      src={displayedImage}
                      alt={projects[currentProjectIndex].name}
                      className="img-fluid"
                    />
                  </div>
                  <div className="thumbnail-container d-flex justify-content-center">
                    {projects[currentProjectIndex].thumbnails.map(
                      (thumbnail, index) => (
                        <div key={index} className="thumbnail mx-1 mb-5">
                          <img
                            src={thumbnail}
                            alt={`Thumbnail ${index + 1}`}
                            className="img-fluid"
                            onClick={() => setDisplayedImage(thumbnail)}
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="col-1 d-flex align-items-center justify-content-center position-relative d-block">
                  <button
                    className="btn btn-outline-light position-absolute top-50 start-50 translate-middle d-none d-sm-block"
                    onClick={nextProject}
                  >
                    <i className="bi bi-chevron-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Our Work */}

          <div className="container p-5    py-5" id="ourWork">
            <div className="row">
              <div className="col-12 ">
                <div className="text-center">
                  <h1>Our Work</h1>
                </div>
              </div>
            </div>
            <div className="row mt-5 outlin coloni">
              <div className="col-lg-5 position-relative  text-center ">
                <h1 className="position-absolute top-50 start-50 translate-middle">
                  Commercial
                </h1>
              </div>
              <div className="col-lg-7   p-4">
                <div
                  id="carouselExampleSlidesOnly"
                  class="carousel slide "
                  data-bs-ride="carousel"
                >
                  <div class="carousel-inner ">
                    <div class="carousel-item active">
                      <img src={comercial} class="d-block w-75 " alt="..." />
                    </div>
                    <div class="carousel-item">
                      <img src={comercial2} class="d-block w-75" alt="..." />
                    </div>
                    <div class="carousel-item">
                      <img src={comercial3} class="d-block w-75" alt="..." />
                    </div>
                    <div className="carousel-item">
                      <img src={comercial4} class="d-block w-75" alt="" />
                    </div>
                    <div className="carousel-item">
                      <img src={comercial5} class="d-block w-75" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="text-center">
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vel
              suscipit deleniti, est quibusdam at facilis dignissimos officia
              aliquam, ipsam voluptatibus quia. Qui ullam aliquam velit quisquam
              consectetur quam recusandae eum!
            </p>
          </div> */}
            </div>

            {/* Residencail */}
            <div className="row mt-5 outlin coloni">
              <div className="col-lg-5 position-relative text-center ">
                <h1 className="position-absolute top-50 start-50 translate-middle">
                  Residencial
                </h1>
              </div>
              <div className="col-lg-7  p-4">
                <div
                  id="carouselExampleSlidesOnly"
                  class="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      <img src={residental} class="d-block w-75" alt="..." />
                    </div>
                    <div class="carousel-item">
                      <img src={residental2} class="d-block w-75" alt="..." />
                    </div>
                    <div class="carousel-item">
                      <img src={residental3} class="d-block w-75" alt="..." />
                    </div>
                    <div class="carousel-item">
                      <img src={residental4} class="d-block w-75" alt="..." />
                    </div>
                    <div class="carousel-item">
                      <img src={residental5} class="d-block w-75" alt="..." />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="text-center">
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vel
              suscipit deleniti, est quibusdam at facilis dignissimos officia
              aliquam, ipsam voluptatibus quia. Qui ullam aliquam velit quisquam
              consectetur quam recusandae eum!
            </p>
          </div> */}
            </div>

            {/*FarmHouse */}
            <div className="row mt-5 outlin coloni">
              <div className="col-lg-5 position-relative text-center coloni">
                <h1 className="position-absolute top-50 start-50 translate-middle">
                  FarmHouse
                </h1>
              </div>
              <div className="col-lg-7  p-4">
                <div
                  id="carouselExampleSlidesOnly"
                  class="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      <img src={comercial} class="d-block w-75" alt="..." />
                    </div>
                    <div class="carousel-item">
                      <img src={comercial2} class="d-block w-75" alt="..." />
                    </div>
                    <div class="carousel-item">
                      <img src={comercial3} class="d-block w-75" alt="..." />
                    </div>
                    <div class="carousel-item">
                      <img src={comercial4} class="d-block w-75" alt="..." />
                    </div>
                    <div class="carousel-item">
                      <img src={comercial5} class="d-block w-75" alt="..." />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="text-center">
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vel
              suscipit deleniti, est quibusdam at facilis dignissimos officia
              aliquam, ipsam voluptatibus quia. Qui ullam aliquam velit quisquam
              consectetur quam recusandae eum!
            </p>
          </div> */}
            </div>

            {/* Colonization */}
            <div className="row mt-5 outlin coloni">
              <div className="col-lg-5 position-relative text-center coloni">
                <h1 className=" position-absolute top-50 start-50 translate-middle ">
                  Colonization
                </h1>
              </div>
              <div className="col-lg-7  p-4">
                <div
                  id="carouselExampleSlidesOnly"
                  class="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      <img src={residental} class="d-block w-75" alt="..." />
                    </div>
                    <div class="carousel-item">
                      <img src={residental2} class="d-block w-75" alt="..." />
                    </div>
                    <div class="carousel-item">
                      <img src={residental3} class="d-block w-75" alt="..." />
                    </div>
                    <div class="carousel-item">
                      <img src={residental4} class="d-block w-75" alt="..." />
                    </div>
                    <div class="carousel-item">
                      <img src={residental5} class="d-block w-75" alt="..." />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="text-center">
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vel
            suscipit deleniti, est quibusdam at facilis dignissimos officia
            aliquam, ipsam voluptatibus quia. Qui ullam aliquam velit quisquam
            consectetur quam recusandae eum!
          </p>
        </div> */}
          </div>

          {/* buying property */}
          <div className="container p-5 py-5">
            <div className="row">
              <div className="col-12 text-center">
                <div className=" ">
                  <h1>Buying Property Made Easy</h1>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-lg-4">
                <div className="text-center mb-4">
                  <img src={Geniinebuy} className="w-25" alt="" />
                </div>

                <div className="">
                  <div className="text-center mb-5  ">
                    <h1>Genuine Properties</h1>
                  </div>
                  <div>
                    <p className="fs-5" style={{ textAlign: "justify" }}>
                      Technically speaking, event streaming is the practice of
                      capturing data in real-time from event sources like
                      databases, sensors, mobile devices, cloud services, and
                      software applications in the form of streams of events;
                      storing these event streams durably for later retrieval;
                      manipulating, processing, and reacting to the event
                      streams in real-time as well as retrospectively; and
                      routing the event streams to different destination
                      technologies as needed. Event streaming thus ensures a
                      continuous flow and interpretation of data so that the
                      right information is at the right place, at the right
                      time.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="text-center mb-4">
                  <img src={hasslebuy} alt="" className="w-25" />
                </div>

                <div className="">
                  <div className="text-center">
                    <h1>Hassle Free Documantation </h1>
                  </div>
                  <div>
                    <p className="fs-5" style={{ textAlign: "justify" }}>
                      At our company, we understand the importance of providing
                      hassle-free documentation to our clients. Our system is
                      designed to process government and official documents as
                      seamless as possible, with no complicated steps or
                      technical difficulties. Our clients can rely on us that
                      their documents are in safe hands and that their
                      experience with us will be smooth and stress-free.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="text-center mb-4">
                  <img src={Coveragebuy} alt="" className="w-25" />
                </div>

                <div className="">
                  <div className="text-center mb-5">
                    <h1>Large Coverage</h1>
                  </div>
                  <div>
                    <p className="fs-5" style={{ textAlign: "justify" }}>
                      Our extensive coverage includes residential, commercial,
                      and industrial properties in various locations, from urban
                      to rural areas. We strive to provide our clients with a
                      diverse selection of properties that meet their specific
                      requirements and budget. Our team is dedicated to helping
                      clients find the perfect property that fits their needs,
                      and we are always expanding our coverage to ensure that we
                      are meeting the demands of our clients.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Why choose use */}

          <div className="container-fluid why py-5 ">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="text-center">
                    <h1 style={{ color: "white" }}>Why Choose Use </h1>
                  </div>
                </div>
              </div>

              <div className="row py-5 mt-5">
                <div className="col-lg-3 col-md-3 mt-4 col-6   text-center  ">
                  <div className="boxes ">
                    <div className="p-3">
                      <img src={Primelocation} className="w-50" alt="" />
                    </div>
                    <div className="p-2">
                      <h3>Prime{ "   "} Location</h3>
                    </div>
                    {/* <div style={{textAlign:"center"}} className="p-3">
                    <p>We build the trust of our buyers by delivering properties as per the scheduled timeline.</p>
                  </div> */}
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 mt-4 col-6 text-center ">
                  <div className="boxes">
                    <div className="p-3">
                      <img src={Timely} className="w-50" alt="" />
                    </div>
                    <div className="p-2">
                      <h3>Timely Possession</h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 mt-4 col-6 text-center ">
                  <div className="boxes">
                    <div className="p-3">
                      <img src={quality} className="w-50" alt="" />
                    </div>
                    <div className="p-2">
                      <h3>Quality Construction</h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 mt-4 col-6 text-center ">
                  <div className="boxes ">
                    <div className="p-3">
                      <img src={morden} className="w-50" alt="" />
                    </div>
                    <div className="p-2">
                      <h3>Modern Amenities</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Our Core Team */}

          <div className="container mb-5 py-5">
            <h2 className="text-center mb-5">Our Core Team</h2>
            <div className="row">
              {teamMembers.map((member) => (
                <div
                  className="col-md-3 col-sm-6 col-6 text-center mb-4"
                  key={member.id}
                >
                  <div className="team-member">
                    <div className="avatar-placeholder mb-3">
                      <img
                        src={member.image}
                        alt={member.name}
                        className="rounded-circle img-fluid"
                      />
                    </div>
                    <h5 className="mb-0">{member.name}</h5>
                    <p className="text-muted">{member.role}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* contactform */}

          <div className="container-fluid  " id="contact">
            <div className="row min-vh-100 ">
              <div
                className="col-sm-6   app-container    "
                // style={{ height: "120vh" }}
              >
                <div className="form-section py-3 p-4 mt-5 my-5 w-75 m-auto">
                  <div>
                    <h2
                      className="text-center mb-5 fs-1"
                      style={{ fontWeight: "800" }}
                    >
                      Schedule a meeting with our team
                    </h2>
                  </div>
                  {/* <div className="text-center">
                    <p className="" style={{ color: "white" }}>
                      Subtext
                    </p>
                  </div> */}
                  <form>
                    <div className="form-group mb-4 ">
                      <label
                        htmlFor="fullName "
                        className="mb-2"
                        // style={{ color: "white" }}
                      >
                        Full Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="fullName"
                        placeholder="Full Name"
                      />
                    </div>

                    <div className="form-group mb-4 ">
                      <label htmlFor="mobile" className="mb-2">
                        Mobile No.
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="mobile"
                        placeholder="Mobile No."
                      />
                    </div>

                    <div className="form-group mb-4 ">
                      <label htmlFor="email" className="mb-2" >
                        Email Address
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Email Address"
                      />
                    </div>

                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group mb-4 ">
                          <label htmlFor="city" className="mb-2">
                            City
                          </label>
                          <input type="text" className="form-control" placeholder="City" name="" id="" />
                          {/* <select className="form-control" id="city">
                            <option>City</option>
                            <option>City 1</option>
                            <option>City 2</option>
                          </select> */}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group mb-4 ">
                          <label htmlFor="Inquire" className="mb-2">
                            Inquire
                          </label>
                          <input type="text" className="form-control" placeholder="Inquire" name="" id="" />
                          {/* <select className="form-control" id="subject">
                            <option>Subject</option>
                            <option>Subject 1</option>
                            <option>Subject 2</option>
                          </select> */}
                        </div>
                      </div>
                    </div>
                    <div className="text-center mt-3">
                      <button type="submit" className=" w-25 p-2 sub fs-5 ">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-md-6 py-5 info-section">
                <div className="row text-center">
                  <div className="col-sm-4 col-4">
                    <h1>250+</h1>
                    <p>
                    Projects Completed</p>
                  </div>
                  <div className="col-sm-4 col-4">
                    <h1>99.9%</h1>
                    <p>Satisfactory rate</p>
                  </div>
                  <div className="col-sm-4 col-4">
                    <h1>10+</h1>
                    <p>Years Of Experienced</p>
                  </div>
                </div>
                <div className="row mt-5 py-5">
                  <div className="col-sm-12 text-center">
                    <h4 className="fs-1">Interested in working with us?</h4>
                    <p className="p-3">
                      For clients, experience exceptional service and tailored
                      real estate solutions from our dedicated team. For
                      potential team members, join RS Properties for a rewarding
                      career with growth opportunities, an innovative
                      environment, and comprehensive benefits. Contact us today
                      to start your journey with us!
                    </p>
                  </div>
                </div>
                <div className="row  text-center">
                  <div className="col-sm-6 col-12 ">
                    <div>
                      <h1>Work Email</h1>
                    </div>
                    <div className="">
                      <span className="fs-5">
                        r.sprosaini09@gmail.com
                        <a
                          href="http://"
                          target="_blank"
                          rel="noopener noreferrer"
                        ></a>
                      </span>
                    </div>
                  </div>

                  <div className="col-sm-6 col-12  ">
                    <div>
                      <h1>Work Phone No.</h1>
                    </div>
                    <div className="  ">
                      <span className="fs-5">
                        +91 9829820009
                        <a
                          href="http://"
                          target="_blank"
                          rel="noopener noreferrer"
                        ></a>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row  mt-5  ">
                  <div className="col-sm-12 text-center">
                    <div>
                      <h1>Office Address</h1>
                    </div>
                    <div className="mb-3">
                      <span>
                        <span className="fw-bold">GST Number</span> :
                        08AOSPM5824Q1ZX
                      </span>
                    </div>
                    <div className="w-50 text-center mx-auto">
                      <span>
                        C/O R S PROPETIY AND BUILDER SHOP NO U-3 SUSHIL COMPLEX
                        AND S-18 HARI BHAU UPADHAY NAGAR MAIN AJMER - 305001
                        RAJASTHAN INDIA
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* footer */}

          <footer className="backcolor py-4">
            <div className="container">
              <div className="row">
                <div className="col-md-5 mb-4 mt-3 text-md-start text-center">
                  <img
                    src={logo}
                    alt="Logo"
                    className="w-25"
                    style={{ cursor: "pointer" }}
                  />
                </div>

                <div className="col-md-7 mt-5 text-md-end text-center">
                  <ul className="list-unstyled d-flex flex-wrap justify-content-md-end justify-content-center">
                    <li className="p-2 fs-5 list-inline-item">
                      <span
                        style={{ cursor: "pointer", color: "white" }}
                        onClick={() => scrollToSection("About")}
                      >
                        About Us
                      </span>
                    </li>
                    <li className="p-2 fs-5 list-inline-item">
                      <span
                        style={{ cursor: "pointer", color: "white" }}
                        onClick={() => scrollToSection("service")}
                      >
                        Service
                      </span>
                    </li>
                    <li className="p-2 fs-5 list-inline-item">
                      <span
                        style={{ cursor: "pointer", color: "white" }}
                        onClick={() => scrollToSection("ourWork")}
                      >
                        Our Work
                      </span>
                    </li>
                    <li className="p-2 fs-5 list-inline-item">
                      <span
                        style={{ cursor: "pointer", color: "white" }}
                        onClick={() => scrollToSection("contact")}
                      >
                        Contact Us
                      </span>
                    </li>
                  </ul>
                  <div className="d-flex justify-content-end w-25 mx-auto ps-5      mt-4">
                    <a
                      href="https://"
                      className="link pe-3"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={facebook} alt="Facebook" className="" />
                    </a>
                    <a
                      href="https://"
                      className="link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={watapp} alt="WhatsApp" className="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default Landing;
